.home-page {
  align-items: center;
  background-color: #1d1d1d;
  color: #f1f1f1;
  display: flex;
  flex-direction: column;
  font-size: 6rem;
  gap: 1rem;
  height: 100vh;
  justify-content: center;
}
